<template lang="pug">
div(v-if='Cotizacion')
  //-Pc
  .d-none.d-md-block
    .contenedor-pc
      v-card(style='margin-left: 5%; margin-right: 5%; border-radius: 80px' max-height='550px' min-height='550px').elevation-0.lado_izquierdo
        v-row
          v-img(:src='Cotizacion.celular' max-height='680px' min-height='520px' min-width='397px').imagen
          .columna
            v-container
              v-row.mb-6.ml-1
                h2.primario--text Cotización
              v-text-field( :rules='[Reglas.requerido, Reglas.solo_letras]' v-model='información["Nombre: "]' label='Nombre' gi solo flat style='min-width: 450px; max-height: 60px')
              v-text-field( :rules='[Reglas.requerido, Reglas.email_valido]' v-model='información["Correo: "]' flat label='Correo:'  solo )
              v-text-field( :rules='[Reglas.requerido, Reglas.solo_números]'  v-model='información["Teléfono: "]' flat label='Celular:' solo )
              v-textarea(background-color="white"  placeholder='Mensaje' v-model='información.Servicio' flat solo)
              v-row.justify-center
                v-btn(rounded @click='enviar()' :loading='enviando_correo').primario.pr-9.pl-9
                  span.white--text Enviar
  //-Móvil
  .d-md-none(style='margin-top: 80px; margin-bottom: 50px')
    .movil
      v-row.justify-center.mb-1
          v-img(:src='Cotizacion.celular_movil' max-height='100%' min-height='100%' style='margin-left: 15%; margin-right: 15%; ')
      v-card(style='border-radius: 40px' max-height='520px' min-height='520px').elevation-0.lado_izquierdo
        v-container
          v-row.mb-6.ml-1.justify-center.mt-6
            h2.primario--text Cotización
          v-text-field( :rules='[Reglas.requerido, Reglas.solo_letras]' v-model='información["Nombre: "]' label='Nombre' flat solo)
          v-text-field( :rules='[Reglas.requerido, Reglas.email_valido]' v-model='información["Correo: "]'  label='Correo:' flat solo )
          v-text-field( :rules='[Reglas.requerido, Reglas.solo_números]'  v-model='información["Teléfono: "]' label='Celular:' flat solo )
          v-textarea(background-color="white"  placeholder='Mensaje' v-model='información.Servicio' flat solo)
          v-row.justify-center
            v-btn(x-large rounded @click='enviar()' :loading='enviando_correo').primario.pr-9.pl-9
              span.white--text Enviar
</template>
<style lang="sass" scoped>
  .v-textarea textarea
    padding: 1px!important
    min-height: 80px!important
    max-height: 80px!important
</style>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data: ()=>({
    información: {
      "Forma de contacto": [],
    },
    validación: false,
    enviando_correo: false
  }),
  computed: {
    ...mapState({
      Reglas: ({Ayudas})=>Ayudas.reglas,
    }),
  },
  methods:{
    ...mapActions({
      enviar_correo_electronico: 'Contacto/enviar_correo_electronico',
      activar_snackbar: 'Ayudas/activar_snackbar',
    }),
    enviar(){
      const titulo = 'Información del contacto'
      const asunto = `Nuevo contacto`
      let cuerpo = `
      <table>`
      Object.keys(this.información).map( i => cuerpo+='<tr><td>' + i + '</td><td>' + this.información[i] + '</td></tr>')
      cuerpo += `</table>`
      const { configuracion_de_correo, correo_electronico} = this.Contacto
      this.enviando_correo = true
      console.log(configuracion_de_correo)
      this.enviar_correo_electronico({titulo, cuerpo, asunto, correo_electronico, configuracion_de_correo})
      .then( () => {
        this.activar_snackbar('Se ha enviado su mensaje. Nos pondremos en contacto lo más pronto posible.')
        this.enviando_correo = false
        this.información  = {
          "Forma de contacto": [],
        }
      })
    },
  },
}
</script>
<style lang="sass" scoped>
.contenedor-pc
  margin-top: 100px
  .imagen
    position: absolute
    top: -6%
    left: 10%
  .columna
    position: absolute
    top: 7%
    left: 50%
.imagen
  position: absolute
  top: 20%
</style>